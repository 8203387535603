import { NewsPageListItem } from 'Components/Page/Dto/NewsPageListItem';
import { SUPPORTED_FILE_EXTENSIONS } from '../../utils/adjustLinks';
import { LinkProperty } from '@episerver/spa-core/dist/Property';
import IContent from '@episerver/spa-core/dist/Models/IContent';
import { ContentDelivery } from '@episerver/spa-core';
import { useEffect, useRef } from 'react';
import store from '../../store';

export const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export const dateTimeFormat = (datetime: string, culture: string, monthFormat: '2-digit' | 'long' = '2-digit') => {
    const dateString = (datetime + '').split('T')[0];
    if (!dateString) return '';

    return dateFormat(dateString, culture, monthFormat);
};

export const dateFormat = (datetime: string, culture: string, monthFormat: '2-digit' | 'long' = '2-digit') => {
    const date = new Date(datetime);

    if (culture === 'en') return date.toLocaleDateString('en-GB');

    return date.toLocaleDateString('nb', { year: 'numeric', month: monthFormat, day: '2-digit' });
};

export function isCurrentPageInTopMenu(contentGuid: string) {
    const menuIds = store.getState()?.menuIds;
    let menuWithFocus = '';
    if (menuIds != null && menuIds.length > 0) {
        store.getState()?.menuIds.forEach((guid: string[]) => {
            if (contentGuid == guid[0]) {
                menuWithFocus = guid[1];
            }
        });
    }
    store.dispatch({ type: 'menuWithFocus', payload: menuWithFocus });
}

function decodeHtml(html: string) {
    if (html == null) {
        return '';
    }
    return html
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'")
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ')
        .replace(/&Aring;/g, 'Å')
        .replace(/&AElig;/g, 'Æ')
        .replace(/&Oslash;/g, 'Ø')
        .replace(/&aelig;/g, 'æ')
        .replace(/&oslash;/g, 'ø')
        .replace(/&aring;/g, 'å')
        .replace(/&eacute;/g, 'é')
        .replace(/&ordm;/g, 'º');
}

function stripHtml(html: string) {
    const decode = decodeHtml(html);
    const regex = /(<([^>]+)>)/gi;
    return decode?.replace(regex, '');
}

export const getDescription = (currentContent: any): string => {
    if ((currentContent as any)?.metaDescription?.value) {
        return stripHtml((currentContent as any)?.metaDescription?.value).substring(0, 300);
    } else if ((currentContent as any)?.mainIntro?.value) {
        return stripHtml((currentContent as any)?.mainIntro?.value).substring(0, 300);
    } else if ((currentContent as any)?.shortDescription?.value) {
        return stripHtml((currentContent as any)?.shortDescription?.value).substring(0, 300);
    }

    return '';
};

export const getNewsDescription = (currentContent: NewsPageListItem): string => {
    let description = '';
    if (currentContent?.ShortDescription) {
        description = stripHtml(currentContent?.ShortDescription).substring(0, 150);
    } else if (currentContent?.MainIntro) {
        description = stripHtml(currentContent?.MainIntro).substring(0, 150);
    }

    if (description.length > 149) description += '...';

    return description;
};

export function IsRecycleBinLink(link: LinkProperty): boolean {
    if (link.href.includes('~/link') && link.contentLink == null) {
        return true;
    }
    if (link.href.includes('/Recycle-Bin')) {
        return true;
    }
    return false;
}

export function IsMediaLink(link: LinkProperty) {
    return SUPPORTED_FILE_EXTENSIONS.some((el) => link?.href?.includes(el));
}

export function isExternalURL(constructedUrl: URL) {
    if (constructedUrl.origin === 'null') {
        return false;
    }
    return constructedUrl.origin !== location.origin;
}

export function isStringURLAnExternalURL(Url: string) {
    const constructedUrl = new URL(Url);
    if (constructedUrl) {
        if (constructedUrl.origin === 'null') {
            return false;
        }
        return constructedUrl.origin !== location.origin;
    }
    return false;
}

export function formatTelephoneNumber(telephoneNumber: string) {
    const match = telephoneNumber.match(/\d{2}/g);
    if (match) {
        if (match.length == 4) {
            return match[0] + ' ' + match[1] + ' ' + match[2] + ' ' + match[3];
        }
    }
    return telephoneNumber;
}

export function formatOrganizationNumber(organizationNumber: number) {
    if (organizationNumber == null) {
        return '';
    }
    const organizationNumberAsString = organizationNumber.toString();
    const match = organizationNumberAsString.match(/\d{3}/g);
    if (match) {
        return match[0] + ' ' + match[1] + ' ' + match[2];
    }
    return organizationNumber;
}

export function handlePossibleStartPageLanguageUrlPath(parentPageUrl: string): string {
    if (!parentPageUrl || parentPageUrl == '/') {
        return '/';
    }

    const url = new URL(parentPageUrl);
    if (url != null) {
        const pathname = url.pathname;
        if (pathname == '/no/' || pathname == '/nn/' || pathname == '/se/' || pathname == '/en/') {
            return '/';
        }
    }
    return parentPageUrl;
}

export function replaceAll(str: string, find: string, replace: string) {
    if (str == null) {
        return '';
    }
    return str.replace(new RegExp(find, 'g'), replace);
}

export function isContentPathEqualToUrlPath(contentUrl: string, urlPath: string) {
    if (contentUrl == null || urlPath == null) {
        return true;
    }
    try {
        if (new URL(contentUrl).pathname != urlPath && new URL(contentUrl).pathname != urlPath + '/') {
            return false;
        }
    } catch (_) {
        return true;
    }
    return true;
}

export function getPageTypeAsString(iContent: IContent) {
    const contentType = iContent.contentType;
    if (contentType.length > 1) {
        return contentType[1].toLowerCase();
    }
    return iContent.name.toString().toLowerCase();
}

export function getLinkTitleFromLinkProperty(link: ContentDelivery.LinkProperty) {
    if (link == null) {
        return '';
    }
    if (link.title) {
        return link.title;
    }
    return link.text;
}

export function getLinkUrlFromLinkProperty(link: ContentDelivery.LinkProperty) {
    if (link?.contentLink?.url) {
        return link?.contentLink?.url;
    }
    return link?.href;
}

export function getRelativeUrl(absoluteUrl: string) {
    try {
        const url = new URL(absoluteUrl);
        return `${url.pathname}${url.search}${url.hash}`;
    } catch {
        // If the input was not a valid absolute URL, assume it is a relative URL
        return absoluteUrl;
    }
}
